<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select">
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="申请编号" value="1">申请编号</el-option>
              <el-option label="会员编号" value="2">会员编号</el-option>
              <el-option label="手机号" value="3">手机号</el-option>
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop="apsn"
          label="申请信息" width="250"
      >
        <template slot-scope="scope">
          <span v-html="
          '编号：'+scope.row.apsn+'<br />'+
          '申请人：'+scope.row.apply_user.full_name+'<br />'+
          '审核人：'+scope.row.operate_user.full_name
"></span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="country_name"-->
<!--          label="国家" width="100"-->
<!--      >-->
<!--      </el-table-column>-->
      <el-table-column
          prop="member_info"
          label="会员信息" width="210"
          >
        <template slot-scope="scope">
          <span v-if="scope.row.member_level == '1'" v-html="
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
          <span v-if="scope.row.member_level === '3'" v-html="
          '会员编号：'+scope.row.uname+'<br />'+
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="log_type_name"
          label="申请类型"
      >
      </el-table-column>
      <el-table-column
          prop="amount"
          label="操作金额"
      >
        <template slot-scope="scope">
          <span v-html="
          '申请金额：'+scope.row.amount+'<br />'+
          '手续费：'+scope.row.fee+'<br />'+
          '操作金额：'+scope.row.real_amount
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="操作时间"
      >
        <template slot-scope="scope">
          <span v-html="
          '申请时间：'+scope.row.account_name+'<br />'+
          '审核时间：'+scope.row.card_number
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="附属信息"
      >
        <template slot-scope="scope">
          <span v-html="
          '账户名：'+scope.row.account_name+'<br />'+
          '所属银行：'+scope.row.bank_name+'<br />'+
          '银行卡号：'+scope.row.card_number
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="状态"
      >
      </el-table-column>
      <el-table-column
          prop="apply_remark"
          label="备注"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="apply(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminFinanceReviewApply') && scope.row.status === '0'">审核</el-button>
          <span v-else>-</span>
<!--          <el-button @click="editNet(scope.row, 1)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend') ">改推荐人</el-button><br />-->
<!--          <el-button @click="editNet(scope.row, 2)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberParent') && scope.row.member_level === '3'">改安置人</el-button><br />-->
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>


    <!-- Form -->

    <el-dialog
        title="申请审核"
        :visible.sync="applyDialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="applyFormData" :rules="checkApplyformRules" ref="applyFormData">
        <el-form-item
            label="是否通过"
            :label-width="formLabelWidth" style="text-align: left"
            prop="settle_status"
        >
          <el-switch :disabled="!myfun.checkAuthRule('adminMemberEditMember')"
                     class="switch custom"
                     v-model="applyFormData.status"
                     :active-value="'1'"
                     :inactive-value="'0'"
                     active-text="通过"
                     inactive-text="不通过"
          ></el-switch>
        </el-form-item>
        <el-form-item
            label="备注"
            :label-width="formLabelWidth"
            prop="operate_remark"
        >
          <el-input v-model="applyFormData.operate_remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="applyDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doApply('applyFormData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MemberList',
    components: {
    },
    data() {
      return {
        test: true,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1'
        },
        tableData:[],
        loading: false,
        applyDialogFormVisible: false,
        applyFormData: {
          ids: '0',
          status: 1,
          operate_remark: '',
        },
        checkApplyformRules: {
        },
        formLabelWidth: '120px'
      }
    },
    methods: {
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminFinanceGetApplyList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      onSearch(){
        this.getList(1);
      },

      apply(row) {
        this.applyFormData.ids = row.id;
        this.applyFormData.status = row.status;
        this.applyDialogFormVisible = true;
      },
      doApply(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminFinanceReviewApply.api', this.applyFormData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.applyDialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
    created() {
      this.getList(1);
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 110px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }


  /* switch按钮样式 */
  .switch.custom .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch.custom .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch.custom .el-switch__label--right span{
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch.custom .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch.custom .el-switch__label--left span{
    margin-left: 20px;
  }
  /*显示文字*/
  .switch.custom .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.custom.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
</style>
