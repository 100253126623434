<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item>
        <el-select class="el-select" v-model="formData.country_id"  placeholder="请选择" @change="changeCountry">
          <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select">
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3">身份证号</el-option>
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop=""
          label="国家"
      >
        <template slot-scope="scope">
          {{countryInfo.name}}
        </template>
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="会员信息"
          >
        <template slot-scope="scope">
          <span v-if="scope.row.member_level == '1'" v-html="
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
          <span v-if="scope.row.member_level === '3'" v-html="
          '会员编号：'+scope.row.uname+'<br />'+
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
        </template>
      </el-table-column>

      <el-table-column v-for="(item, key) in accountList" :key="key"
          prop=""
          :label="item.show_name"
      >
        <template slot-scope="scope">
          <span v-if="typeof(scope.row.member_account_balance_list) !== 'undefined' && typeof(scope.row.member_account_balance_list[item.id]) !== 'undefined'">
            {{scope.row.member_account_balance_list[item.id].balance}}
          </span>
          <span v-else>
            0.00
          </span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="modifyAccountBalance(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminFinanceModifyMemberAccountBalance')">资金调整</el-button><br />
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>


    <!-- Form -->

    <el-dialog
        title="账户金额调整"
        :visible.sync="modifyAccountBalanceDialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="modifyAccountBalanceformData" :rules="checkModifyAccountBalanceFormRules" ref="modifyAccountBalanceformData">
        <el-form-item
            label="所属账户"
            :label-width="formLabelWidth"
            ref="memberAccountId"
            prop="memberAccountId"
        >
          <el-select
              v-model="modifyAccountBalanceformData.memberAccountId"  @change="changeMemberAccount"
              placeholder="请选择"
              style="width: 100%"
          >
            <el-option
                v-for="item in accountLogTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="操作类型"
            :label-width="formLabelWidth"
            ref="memberAccountLogTypeId"
            prop="memberAccountLogTypeId"
        >
          <el-select
              v-model="modifyAccountBalanceformData.memberAccountLogTypeId"
              placeholder="请选择"
              style="width: 100%"
          >
            <el-option
                v-for="item in logTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="金额"
            :label-width="formLabelWidth"
            ref="amount"
            prop="amount"
            required>
          <el-input v-model="modifyAccountBalanceformData.amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="备注"
            :label-width="formLabelWidth"
            prop="remark"
        >
          <el-input v-model="modifyAccountBalanceformData.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyAccountBalanceDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doModifyAccountBalance('modifyAccountBalanceformData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AccountBalanceList',
    components: {
    },
    data() {
      return {
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1',
          country_id: '',
        },
        tableData:[],
        loading: false,
        countryList: [],
        accountList: [],
        accountLogTypeList:[],
        logTypeList:[],
        countryInfo: [],
        modifyAccountBalanceDialogFormVisible: false,
        modifyAccountBalanceformData: {},
        checkModifyAccountBalanceFormRules: {
          amount: [
            { required: true, message: "请输入金额，调整金额必须为正数"},
          ],
        },
        formLabelWidth: '120px'
      }
    },
    methods: {
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminFinanceMemberAccountBalanceList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
            this.accountList = result.data.accountList;
            this.countryInfo = result.data.countryInfo;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      onSearch(){
        this.getList(1);
      },
      resetForm() {
        this.modifyAccountBalanceformData = {
            id: '0',
            memberAccountId: '',
            memberAccountLogTypeId:'0',
            amount:'',
            remark:''
        };
        this.modifyAccountBalanceformData.memberAccountId = this.accountLogTypeList[0].id;
        this.logTypeList = this.accountLogTypeList[0].member_account_log_type;
        this.modifyAccountBalanceformData.memberAccountLogTypeId = this.logTypeList[0].id;
      },
      modifyAccountBalance(row) {
        this.resetForm('modifyAccountBalanceformData');
        this.modifyAccountBalanceformData.id = row.id;
        this.modifyAccountBalanceformData.country_id = this.formData.country_id;
        this.modifyAccountBalanceDialogFormVisible = true;
      },
      doModifyAccountBalance(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminFinanceModifyMemberAccountBalance.api', this.modifyAccountBalanceformData, "post")
              .then((result) => {
                this.loading = false;
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.modifyAccountBalanceDialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }

              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },

      changeMemberAccount(memberAccountId){
        for (let i in this.accountLogTypeList){
          if(this.accountLogTypeList[i].id === memberAccountId){
            this.logTypeList = this.accountLogTypeList[i].member_account_log_type;
            this.modifyAccountBalanceformData.memberAccountLogTypeId = this.logTypeList[0].id;
          }
        }
      },
      changeCountry(){
        this.getList();
      }
    },
    created() {
      //副接口
      this.myfun
        .request('adminFinanceModifyMemberAccountBalanceDeputy.api', {'country_id':1}, "post")
        .then((result) => {
          if (result.code == "1") {
            this.countryList = result.data.countryList;
            this.formData.country_id = this.countryList[0].id;
            this.accountLogTypeList = result.data.accountLogTypeList;
            this.resetForm();
            this.getList(1);
          } else {
            this.$message({
              message: result.msg,
              type: "warning",
            });
          }
        });
    },
    mounted() {

    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 110px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
