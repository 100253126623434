<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item>
        <el-select class="el-select" v-model="formData.country_id"  placeholder="请选择" @change="changeSearch">
          <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select class="el-select" v-model="formData.memberAccountId"  placeholder="请选择" @change="changeMemberAccount">
          <el-option
              v-for="item in accountLogTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select class="el-select" style="width: 350px" v-model="formData.memberAccountLogTypeId" clearable multiple  placeholder="请选择" @change="changeSearch">
          <el-option
              v-for="item in logTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"

          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select" clearable>
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="会员编号" value="1">会员编号</el-option>
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="身份证号" value="3">身份证号</el-option>
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="会员信息"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.member_level == '1'" v-html="
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
          <span v-if="scope.row.member_level === '3'" v-html="
          '会员编号：'+scope.row.uname+'<br />'+
          '真实姓名：'+scope.row.full_name+'<br />'+
          '手机号码：'+scope.row.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="country_name"
          label="国家"
      >
      </el-table-column>

      <el-table-column
          prop="member_account_name"
          label="账户"
      >
      </el-table-column>
      <el-table-column
          prop="member_account_log_type_name"
          label="日志类型"
      >
      </el-table-column>
      <el-table-column
          prop="amount"
          label="收支"
      >
        <template slot-scope="scope">
          <span style="color: #eb6060" v-if="scope.row.operate_type === '0'" v-html="
          '-'+scope.row.amount
"></span>
          <span style="color: green" v-if="scope.row.operate_type == '1'" v-html="
          '+'+scope.row.amount
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="final_balance"
          label="结余"
      >
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
      >
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="时间"
      >
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountLogList',
    components: {
    },
    data() {
      return {
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1',
          country_id: '',
          memberAccountId: '',
          memberAccountLogTypeId: []
        },
        tableData:[],
        loading: false,
        countryList: [],
        accountList: [],
        accountLogTypeList:[],
        logTypeList:[],
        formLabelWidth: '120px'
      }
    },
    methods: {
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminFinanceGetMemberAccountLog.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
            this.accountList = result.data.accountList;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      onSearch(){
        this.getList(1);
      },
      resetForm() {
        this.modifyAccountBalanceformData = {
            id: '0',
            memberAccountId: '',
            memberAccountLogTypeId: [],
            amount:'',
            remark:''
        };
        this.formData.memberAccountId = this.accountLogTypeList[0].id;
        this.logTypeList = this.accountLogTypeList[0].member_account_log_type;
        // this.formData.memberAccountLogTypeId = this.logTypeList[0].id;
      },
      modifyAccountBalance(row) {
        this.resetForm('modifyAccountBalanceformData');
        this.modifyAccountBalanceformData.id = row.id;
        this.modifyAccountBalanceformData.country_id = this.formData.country_id;
        this.modifyAccountBalanceDialogFormVisible = true;
      },
      doModifyAccountBalance(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminFinanceModifyMemberAccountBalance.api', this.modifyAccountBalanceformData, "post")
              .then((result) => {
                this.loading = false;
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.modifyAccountBalanceDialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }

              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },

      changeMemberAccount(memberAccountId){
        for (let i in this.accountLogTypeList){
          if(this.accountLogTypeList[i].id === memberAccountId){
            this.logTypeList = this.accountLogTypeList[i].member_account_log_type;
            this.formData.memberAccountLogTypeId = [];
            this.getList();
          }
        }
      },
      changeSearch(){
        this.getList();
      }
    },
    created() {
      //副接口
      this.myfun
        .request('adminFinanceGetMemberAccountLogDeputy.api', {'country_id':1}, "post")
        .then((result) => {
          if (result.code == "1") {
            this.countryList = result.data.countryList;
            this.formData.country_id = this.countryList[0].id;
            this.accountLogTypeList = result.data.accountLogTypeList;
            this.resetForm();
            this.getList(1);
          } else {
            this.$message({
              message: result.msg,
              type: "warning",
            });
          }
        });
    },
    mounted() {

    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 110px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>
